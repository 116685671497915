import React, { useState, useEffect } from "react";
import { Clock, Settings, X } from "lucide-react";
import { Modal } from "react-bootstrap";
import axios from "axios";

const Timer = ({ isRunning, onTimerStart, initialTime = 0, questionId }) => {
  const [time, setTime] = useState(initialTime);
  const [timerStarted, setTimerStarted] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [autoStartTimer, setAutoStartTimer] = useState(false);
  const [questionAttempted, setQuestionAttempted] = useState(false);
  const user = JSON.parse(localStorage.getItem("userProfile"));

  useEffect(() => {
    if (user && user.settings && user.settings.toggleQuestionTimer !== undefined) {
      setAutoStartTimer(user.settings.toggleQuestionTimer);
    }
  }, [user]);

  useEffect(() => {
    setTime(initialTime);
    setTimerStarted(false);
    setQuestionAttempted(false);

    if (autoStartTimer && onTimerStart) {
      setTimeout(() => {
        handleStartTimer();
      }, 0);
    }
  }, [questionId, initialTime]);

  useEffect(() => {
    if (autoStartTimer && !timerStarted && onTimerStart && (time === 0 || !questionAttempted)) {
      handleStartTimer();
    }
  }, [autoStartTimer]);

  useEffect(() => {
    if (isRunning === false) {
      setTimerStarted(false);
      setQuestionAttempted(true);
    }
  }, [isRunning]);

  useEffect(() => {
    let interval;

    if (timerStarted) {
      interval = setInterval(() => {
        setTime((prevTime) => prevTime + 1);
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [timerStarted]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
  };

  const handleStartTimer = () => {
    setTimerStarted(true);
    if (onTimerStart) onTimerStart();
  };

  const toggleSettings = () => {
    setShowSettings(!showSettings);
  };

  const handleAutoStartChange = (event) => {
    const newValue = event.target.checked;

    setAutoStartTimer(newValue);

    axios
      .patch(`/api/user/profile/settings`, { toggleQuestionTimer: newValue })
      .then((response) => {
        if (response?.status === 200) {
          localStorage.setItem("userProfile", JSON.stringify(response.data.data));
        }
      })
      .catch((err) => console.log(err));
  };

  if (timerStarted || (time > 0 && !timerStarted)) {
    return (
      <>
        <div className="d-flex w-100 align-items-center position-relative">
          <div
            className="rounded-pill px-3 py-1 d-flex align-items-center position-absolute start-50 translate-middle-x"
            style={{
              borderWidth: "2px",
              borderColor: "var(--qzr-timer-border)",
              borderStyle: "dashed",
              backgroundColor: "var(--qzr-timer-bg)",
              color: "var(--qzr-timer-text)",
            }}
          >
            <div style={{ width: "24px", display: "flex", justifyContent: "center" }}>
              <Clock size={16} color="#9270d4" />
            </div>
            <div style={{ width: "45px", color: "#9270d4", fontWeight: "bold" }}>{formatTime(time)}</div>
          </div>

          <button
            onClick={toggleSettings}
            className="btn btn-link p-0 ms-auto d-flex align-items-center"
            style={{ display: "inline-flex", whiteSpace: "nowrap" }}
          >
            <Settings size={16} style={{ marginRight: "4px" }} />
            <span className="d-none d-lg-inline">Settings</span>
          </button>
        </div>

        <Modal show={showSettings} onHide={toggleSettings} size="md" aria-labelledby="timer-settings-modal" centered>
          <Modal.Header
            className="d-flex justify-content-between align-items-center border-bottom"
            style={{ paddingRight: "16px" }}
          >
            <Modal.Title id="timer-settings-modal">Practice Mode Settings</Modal.Title>
            <X style={{ cursor: "pointer" }} onClick={toggleSettings} />
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex justify-content-between align-items-start">
              <div>
                <div className="fw-bold">Auto-start Timer</div>
                <div className="text-muted small">Start timer automatically when opening question</div>
              </div>
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="autoStartSwitch"
                  checked={autoStartTimer}
                  onChange={handleAutoStartChange}
                />
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }

  return (
    <>
      <div className="d-flex w-100 align-items-center position-relative">
        <button
          onClick={handleStartTimer}
          className="rounded-pill py-2 d-flex align-items-center gap-2 position-absolute start-50 translate-middle-x px-3 py-1"
          style={{
            backgroundColor: "var(--qzr-timer-bg)",
            color: "var(--qzr-timer-text)",
            borderWidth: "2px",
            borderColor: "var(--qzr-timer-border)",
            borderStyle: "solid",
            cursor: "pointer",
          }}
        >
          <Clock size={16} />
          <h5 className="text-nowrap mb-0">Start Timer</h5>
        </button>

        <button
          onClick={toggleSettings}
          className="btn btn-link p-0 ms-auto d-flex align-items-center"
          style={{ display: "inline-flex", whiteSpace: "nowrap" }}
        >
          <Settings size={16} style={{ marginRight: "4px" }} />
          <span className="d-none d-lg-inline">Settings</span>
        </button>
      </div>

      <Modal show={showSettings} onHide={toggleSettings} size="md" aria-labelledby="timer-settings-modal" centered>
        <Modal.Header
          className="d-flex justify-content-between align-items-center border-bottom"
          style={{ paddingRight: "16px" }}
        >
          <Modal.Title id="timer-settings-modal">Practice Mode Settings</Modal.Title>
          <X style={{ cursor: "pointer" }} onClick={toggleSettings} />
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-between align-items-start">
            <div>
              <div className="fw-bold">Auto-start Timer</div>
              <div className="text-muted small">Start timer automatically when opening question</div>
            </div>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="autoStartSwitch"
                checked={autoStartTimer}
                onChange={handleAutoStartChange}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Timer;
