import axios from "axios";
import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { error, success } from "tata-js";

const API_URL = process.env.REACT_APP_API_URL;

const NotebookForm = ({ notebook, notebookId, show, hide, refetch = () => {} }) => {
  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    reset,
    formState: { isDirty, errors },
  } = useForm({
    defaultValues: {},
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (notebook) {
      setValue("notebook", notebook);
    } else {
      setValue("notebook", "");
    }
  }, [notebook]);

  const saveNotebook = (data) => {
    if (notebookId && notebook) {
      updateNotebook();
      return;
    }
    setLoading(true);
    axios
      .post(`${API_URL}/api/v1/notebooks`, {
        name: getValues().notebook.trim(),
      })
      .then((res) => {
        setLoading(false);
        refetch();
        hide();
        if (res && res.status === 200) {
          success("Notebook created", "Notebook has been created", {
            position: "tr",
            duration: 5000,
          });
        } else {
          error("Notebook creation failed", "Notebook creation failed", {
            position: "tr",
            duration: 5000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateNotebook = () => {
    setLoading(true);
    axios
      .patch(`${API_URL}/api/v1/notebooks/${notebookId}`, {
        title: getValues().notebook,
      })
      .then((res) => {
        setLoading(false);
        refetch();
        hide();
        success("Notebook updated", "Notebook name updated", {
          position: "tr",
          duration: 5000,
        });
        reset();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // const onSubmit = data => console.log(data);

  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          reset();
          hide();
        }}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter" className="mb-0">
            {notebook ? "Edit Notebook" : "Create Notebook"}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {/* Create form with single input text field */}
          <form onSubmit={handleSubmit(saveNotebook)}>
            <div className="form-group">
              <label htmlFor="notebookField">Notebook Name</label>
              <input
                type="text"
                className="form-control my-2"
                id="notebookField"
                aria-describedby="notebookHelp"
                placeholder="Enter Notebook Name"
                {...register("notebook", {
                  required: true,
                  validate: (value) => value.trim() !== "" || "Notebook name cannot be empty",
                })}
              />
              {/* {JSON} */}
              <small id="note" className="form-text text-muted">
                Make sure to give your notebook a proper name.
              </small>
              {errors && errors.notebook && (
                <small className="text-danger">
                  {errors.notebook.type === "required" && "Notebook name is required"}
                </small>
              )}
              {/* {JSON.stringify(errors || {})} */}
            </div>
            <input disabled={loading} className="btn btn-primary btn-block" type="submit" />

            {/* <button onClick={saveNotebook}>Save</button> */}
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default NotebookForm;
