import React, { useEffect, useState } from "react";

const Modal = ({ children, show, title, onClose }) => {
  const [iframeShow, setIframeShow] = useState(true);

  const handleClose = () => {
    setIframeShow(false);
    onClose();
  };

  useEffect(() => {
    if (show) {
      setIframeShow(true);
    }
  }, [show]);

  return (
    <div
      className={`custom-modal ${show ? "show" : "hidden"}`}
      id="selectPackModal"
      tabIndex="-1"
      role="dialog"
      style={{
        display: show ? "block" : "none",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        zIndex: 20000,
        overflow: "auto",
      }}
    >
      <div
        className="custom-modal-dialog"
        role="document"
        style={{
          width: "100%",
          height: "100%",
          margin: 0,
          padding: 0,
          maxWidth: "100%",
          position: "relative",
          zIndex: 20001,
        }}
      >
        <div
          className="custom-modal-content"
          style={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
            backgroundColor: "white",
            borderRadius: 0,
            outline: 0,
          }}
        >
          <div
            className="custom-modal-header"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "1rem",
              borderBottom: "1px solid #dee2e6",
            }}
          >
            <h4 className="custom-modal-title">{title}</h4>
            <button
              type="button"
              className="close"
              onClick={handleClose}
              style={{
                background: "transparent",
                border: 0,
                fontSize: "1.5rem",
                fontWeight: 700,
                lineHeight: 1,
                color: "#000",
                opacity: 0.5,
                cursor: "pointer",
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div
            className="custom-modal-body"
            style={{
              position: "relative",
              flex: "1 1 auto",
              padding: "1rem",
              height: "calc(100% - 70px)", // Accounting for header height
              overflow: "auto",
            }}
          >
            {iframeShow ? children : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
