import React, { useState, useEffect } from "react";
import LatexCustom from "../../components/global/Latex";
import QuestionView from "./QuestionView";
import SingleBookmark from "./SingleBookmark";
import QUESTION_TYPE_MAP from "../../utils/variables";
import Fancybox from "../global/FancyBox";
import VideoModal from "../global/Modal";
import axios from "axios";
import TakeNotes from "../v3/notes/TakeNotes";

const API_URL = process.env.REACT_APP_API_URL;

function isNumeric(value) {
  return /^-?\d+$/.test(value);
}

const SingleLineQuestionView = ({ question, selectedNotebook, refresh, questionIndex, onViewQuestion }) => {
  const [otp, setOtp] = useState("");
  const [playbackInfo, setPlaybackInfo] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const viewQuestion = (question) => {
    onViewQuestion(question, questionIndex);
  };

  const getVideoCipherOtp = async () => {
    const videoId = question.questionId.videoSolution;
    if (!videoId || isNumeric(videoId)) return;
    try {
      const res = await axios.get(`${API_URL}/api/video/${videoId}/getotp`);
      return {
        otp: res?.data?.data?.otp,
        playBackInfo: res?.data?.data?.playbackInfo,
      };
    } catch (err) {
      console.error(`Error fetching OTP and playback info for videoId ${videoId}. Error ${err.message}`);
    }
  };

  const handleShowVdoCipherVideoSolution = async () => {
    const videoOtp = await getVideoCipherOtp();
    if (!videoOtp) return;
    const { otp, playBackInfo } = videoOtp;
    setOtp(otp);
    setPlaybackInfo(playBackInfo);
    setModalOpen(true);
  };

  return (
    <div className="card w-100">
      <div className="card-header">
        <div className="d-flex justify-content-between">
          <div className="d-flex align-items-center">
            <h5 className="mb-0 text-muted">
              Q {questionIndex + 1} • {QUESTION_TYPE_MAP[question?.questionId?.type]}
            </h5>
          </div>
          <div className="d-flex gap-3">
            <TakeNotes questionId={question?.questionId?._id} notes={question?.questionId?.notes} />
            {question?.questionId?.videoSolution && (
              <div className="col-auto">
                {!isNumeric(question.questionId.videoSolution) ? (
                  // If videoSolution is not a valid number
                  <div>
                    <a className="btn btn-sm btn-danger fancybox" onClick={handleShowVdoCipherVideoSolution}>
                      <i className="fe fe-play me-1"></i>
                      Video Solution
                    </a>
                    <VideoModal
                      title="Video Solution"
                      show={modalOpen}
                      onClose={() => setModalOpen(false)} // Pass onClose prop to close modal
                    >
                      {modalOpen ? (
                        <iframe
                          src={`https://player.vdocipher.com/v2/?otp=${otp}&playbackInfo=${playbackInfo}`}
                          allow="encrypted-media"
                          loading="lazy"
                          allowFullScreen
                          style={{ border: "0", width: "100%", height: "100%", display: "block" }}
                        ></iframe>
                      ) : null}
                    </VideoModal>
                  </div>
                ) : (
                  // If videoSolution is a valid number
                  <Fancybox>
                    <a
                      href={`https://player.vimeo.com/video/${question?.questionId?.videoSolution}?title=0&amp;byline=0&amp;portrait=0`}
                      data-fancybox
                      data-type="iframe"
                      className="btn btn-sm btn-danger fancybox text-nowrap"
                    >
                      <i className="fe fe-play me-1"></i>
                      Video Solution
                    </a>
                  </Fancybox>
                )}
              </div>
            )}
            <SingleBookmark
              question={question}
              questionId={question?.questionId?._id}
              notebookId={selectedNotebook?._id}
              refresh={refresh}
            />
          </div>
        </div>
      </div>
      {/* <div className="ms-2 me-auto">
                <div className="question-row">
                    <div className="d-flex justify-content-end">
                        <div className="" onClick={viewQuestion.bind(this, question)}>
                            <LatexCustom>{question?.questionId?.question?.text}</LatexCustom>
                        </div>
                        <SingleBookmark
                            questionId={question?.questionId?._id}
                            notebookId={selectedNotebook?._id}
                            refresh={refresh}
                        />
                    </div>
                </div>
            </div> */}

      <div className="card-body pb-0">
        <div className="" onClick={viewQuestion.bind(this, question)}>
          <LatexCustom>{question?.questionId?.question?.text}</LatexCustom>
        </div>
      </div>

      <div className="card-footer">
        <button className="btn btn-primary btn-sm" onClick={viewQuestion.bind(this, question)}>
          View Question
        </button>
      </div>
    </div>
  );
};

export default SingleLineQuestionView;
