import axios from "axios";
import React, { useState, useEffect } from "react";
import Bookmark from "../../notebook/BookMark";
import { BookmarkCheck, Bookmark as Bookmark1 } from "lucide-react";

const QuestionBookmark = ({ question, packId, testId }) => {
  const [showBookmark, setShowBookmark] = useState(false);
  const [bookmarkStatus, setBookmarkStatus] = useState(false);
  const fetchBookmarkStatus = async () => {
    axios
      .get(`/api/v1/notebooks/question-status/${question?.questionId?._id}`)
      .then((res) => {
        if (res.data?.data?.status === "Not Added") {
          setBookmarkStatus(false);
        } else {
          setBookmarkStatus(res.data?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (question) fetchBookmarkStatus();
  }, [question]);

  const showBookmarkQuestion = () => setShowBookmark(true);
  const hideBookmark = () => setShowBookmark(false);
  return (
    <>
      <Bookmark
        show={showBookmark}
        hide={hideBookmark}
        questionId={question?.questionId?._id}
        bookmarkStatus={bookmarkStatus}
        refetch={fetchBookmarkStatus}
        packId={packId}
        testId={testId}
      />
      <h5 className="mb-0 text-primary cursor-pointer d-flex align-items-center" onClick={showBookmarkQuestion}>
        {bookmarkStatus ? <BookmarkCheck size={16} /> : <Bookmark1 size={16} />}&nbsp;
        <span className="d-none d-md-inline">{bookmarkStatus ? "Bookmarked" : "Bookmark"}</span>
      </h5>
    </>
  );
};

export default QuestionBookmark;
