import axios from "axios";
import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { error, success } from "tata-js";
import NotebookForm from "./NotebookForm";

const GET_NOTEBOOKS = `/api/v1/notebooks?limit=100`;

const fetchNotebooks = async () => {
  const response = await axios.get(GET_NOTEBOOKS);
  return response.data;
};

const Bookmark = ({ bookmarkStatus, show, hide, questionId, refetch, packId, testId }) => {
  const { register, setValue, getValues } = useForm({
    mode: "onChange",
  });
  const [showNotebookForm, setShowNotebookForm] = useState(false);
  const [showTagForm, setShowTagForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deSelectedNotebooks, setDeSelectedNotebooks] = useState([]);
  const [preSelectedNotebooks, setPreSelectedNotebooks] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const [showArchivedNotebooks, setShowArchivedNotebooks] = useState(false);
  const [archivedNotebookData, setArchivedNotebookData] = useState([]);
  const { data: notebookData, refetch: refetchNotebooks } = useQuery(GET_NOTEBOOKS, fetchNotebooks, {
    enabled: false, // Prevents automatic fetching
    staleTime: Infinity, // Prevents background refetching
    cacheTime: Infinity, // Keeps data cached
  });

  // Add initial fetch when component mounts
  useEffect(() => {
    refetchNotebooks();
  }, []);

  useEffect(() => {
    return () => {
      setShowMore(false);
    };
  }, [show]);

  // const onClickArchivedNotebooks = async () => {
  //   const newState = !showArchivedNotebooks;
  //   setShowArchivedNotebooks(newState)

  //   if(newState) {
  //     const archivedNotebooks = await fetchArchivedNotebooks();
  //     setArchivedNotebookData(archivedNotebooks);
  //   }
  // }

  useEffect(() => {
    if (bookmarkStatus) {
      const selectedNotes = bookmarkStatus?.notebooks?.map((n) => n?._id);
      setPreSelectedNotebooks(selectedNotes);
      if (selectedNotes?.length > 0) {
        setValue("notebookId", selectedNotes);
      }
      setValue("tags", bookmarkStatus.tags);
    } else {
      setValue("notebookId", null);
    }
  }, [bookmarkStatus]);

  const bookmarkQuestion = async () => {
    const { notebookId } = getValues();
    const alreadyBookmarkedNotebooks = bookmarkStatus?.notebooks?.map((n) => n?._id);
    let removedNotebooks;
    if (notebookId) {
      removedNotebooks = alreadyBookmarkedNotebooks?.filter((n) => !notebookId?.includes(n));
    } else {
      removedNotebooks = alreadyBookmarkedNotebooks;
    }
    // return;
    try {
      setLoading(true);
      const response = await axios.post(`/api/v1/notebooks/addQuestion`, {
        questionId,
        notebookId:
          typeof getValues("notebookId") === "string"
            ? [getValues("notebookId")]
            : getValues("notebookId")
            ? getValues("notebookId")
            : [],
        tags: getValues("tags"),
        removedNotebookId: removedNotebooks,
        packId: packId,
        testId: testId,
      });
      hide();
      refetch();
      setLoading(false);
      if (response.data?.isRemoved) {
        success("Question removed", "Question removed from notebooks(s)", {
          position: "tr",
          duration: 5000,
        });
      } else {
        success("Question bookmark Updated", "Question added to notebook(s)", {
          position: "tr",
          duration: 5000,
        });
      }
    } catch (e) {
      error("Error", "Error bookmarking question", {
        position: "tr",
        duration: 5000,
      });
    }
  };

  const showCreateNotebookForm = () => setShowNotebookForm(true);
  const hideCreateNotebookForm = () => {
    setDeSelectedNotebooks([]);
    setPreSelectedNotebooks([]);
    setShowNotebookForm(false);
  };

  const hideTagForm = () => setShowTagForm(false);
  const showTagCreateForm = () => setShowTagForm(true);
  const showMoreNotebooks = () => setShowMore(true);

  return (
    <>
      <NotebookForm notebook={null} show={showNotebookForm} hide={hideCreateNotebookForm} refetch={refetchNotebooks} />

      {/* <TagForm
        hide={hideTagForm}
        show={showTagForm}
        tag={null}
        refetch={refetchTags}
      /> */}
      <Modal show={show} onHide={hide} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter" className="mb-0">
            Add to Notebooks
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {/* {JSON.stringify(bookmarkStatus, null, 2)} */}
          {/* Create form with single input text field */}
          <form>
            <p className="text-muted">Select the notebook(s) you want to add the question to:</p>

            {/* Create checkboxes for notebooks */}
            {notebookData?.data?.notebooks.map((notebook, notebookIndex) => {
              if (notebookIndex < 10 || showMore) {
                return (
                  <div key={notebook._id} className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id={`notebook-${notebook._id}`}
                      value={notebook._id}
                      {...register(`notebookId`, {})}
                    />
                    <label className="form-check-label" htmlFor={`notebook-${notebook._id}`}>
                      {notebook.title}
                    </label>
                  </div>
                );
              } else if (notebookIndex === 10) {
                return (
                  <p className="text-primary mt-3 btn btn-sm btn-light d-block" onClick={showMoreNotebooks}>
                    Show More...
                  </p>
                );
              }
            })}

            {showArchivedNotebooks &&
              archivedNotebookData?.data?.notebooks.map((notebook, notebookIndex) => {
                if (notebookIndex < 10 || showMore) {
                  return (
                    <div key={notebook._id} className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id={`notebook-${notebook._id}`}
                        value={notebook._id}
                        {...register(`notebookId`, {})}
                      />
                      <label className="form-check-label" htmlFor={`notebook-${notebook._id}`}>
                        {notebook.title} (Archived)
                      </label>
                    </div>
                  );
                } else if (notebookIndex === 10) {
                  return (
                    <p className="text-primary mt-3 btn btn-sm btn-light d-block" onClick={showMoreNotebooks}>
                      Show More...
                    </p>
                  );
                }
              })}

            <p className="text-primary mt-3 me-2 btn btn-sm btn-light" onClick={showCreateNotebookForm}>
              Create Notebook
            </p>
          </form>
        </Modal.Body>

        <Modal.Footer className="py-2">
          <button className="btn btn-primary btn-block" onClick={bookmarkQuestion} disabled={loading}>
            Save
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Bookmark;
