import React, { useEffect } from "react";
import { Fancybox as NativeFancybox } from "@fancyapps/ui/dist/fancybox.esm.js";
import "@fancyapps/ui/dist/fancybox.css";

function Fancybox(props) {
  const delegate = props.delegate || "[data-fancybox]";

  useEffect(() => {
    const opts = {
      ...props.options,
      iframe: {
        preload: false,
        css: {
          width: "100%",
          height: "100%",
        },
      },
      fullScreen: {
        autoStart: true, // Ensure fullscreen mode
      },
    };

    NativeFancybox.bind(delegate, opts);

    return () => {
      NativeFancybox.destroy();
    };
  }, [delegate, props.options]);

  return <>{props.children}</>;
}

export default Fancybox;
