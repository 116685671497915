import axios from "axios";
import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import QuestionBookmark from "../global/QuestionBookmark";

const SingleBookmark = ({ marked = true, question = null, questionId, notebookId, refresh, showBookMark = false }) => {
  const [isBookmarked, setIsBookmarked] = useState(marked);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const showConfirmDelete = () => setConfirmDelete(true);
  const hideConfirmDelete = () => setConfirmDelete(false);

  const onBookmark = async () => {
    try {
      await axios.patch(`/api/v1/notebooks/removeQuestion/${notebookId}/${questionId}`);
      setIsBookmarked(!isBookmarked);
      hideConfirmDelete();
      refresh();
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <div className="w-100 d-flex align-items-center gap-2 cursor-pointer text-primary" style={{ textAlign: "right" }}>
        <QuestionBookmark
          question={question}
          testId={question?.questionId?.testId}
          packId={question?.questionId?.packId}
        />
      </div>

      {/* <Modal centered show={confirmDelete} onHide={hideConfirmDelete} size="sm">
        <Modal.Body className="px-4 py-0">
          <div style={{ marginTop: "5%", marginBottom: "5%" }}>
            <p className="text-center mb-0">Are you sure you want to remove this question from the notebook?</p>
            <br />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "10%",
              }}
            >
              <Button size="block" variant="secondary" onClick={hideConfirmDelete}>
                No!
              </Button>
              &nbsp;&nbsp;
              <Button size="block" variant="danger" onClick={onBookmark}>
                Yes! Delete
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal> */}
    </>
  );
};

export default SingleBookmark;
