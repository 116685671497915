import React, { useState, useEffect, useRef, useCallback } from "react";
import { Modal } from "react-bootstrap";
import Question from "./Question";
import SingleBookmark from "./SingleBookmark";
import QUESTION_TYPE_MAP from "../../utils/variables";
import { ChevronLeft, ChevronRight, X } from "lucide-react";
import Timer from "./Timer";

const QuestionView = ({ show, hide, question, notebookId, refresh, onNext, onPrev, disableNext, disablePrev }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showFullScreenQuestionModal, setShowFullScreenQuestionModal] = useState(false);
  const [timerActive, setTimerActive] = useState(false);
  const modalBodyRef = useRef(null);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    const user = JSON.parse(localStorage.getItem("userProfile"));
    if (user) {
      setShowFullScreenQuestionModal(user.settings.showFullScreenQuestionModal);
    }
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Reset timer when question changes
  useEffect(() => {
    setTimerActive(false);
  }, [question]);

  const getButtonPosition = () => {
    if (showFullScreenQuestionModal) return "0px";
    if (windowWidth > 991) return "-100px";
    if (windowWidth > 767) return "-50px";
    if (windowWidth > 575) return "0px";
    return "0px";
  };

  const buttonStyle = {
    width: "40px",
    height: "40px",
    backgroundColor: "var(--nav-custom)",
    border: "none",
    transition: "all 0.3s ease",
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
  };

  const scrollToTop = () => {
    if (modalBodyRef.current) {
      modalBodyRef.current.scrollTop = 0;
    }
  };

  const handlePrevQuestion = () => {
    onPrev();
    scrollToTop();
  };

  const handleNextQuestion = () => {
    onNext();
    scrollToTop();
  };

  const handleKeyNavigation = useCallback(
    (e) => {
      if (!show || e.target.tagName.toLowerCase() === "input" || e.target.tagName.toLowerCase() === "textarea") {
        return;
      }

      if (e.key === "ArrowLeft" && !disablePrev) {
        handlePrevQuestion();
      } else if (e.key === "ArrowRight" && !disableNext) {
        handleNextQuestion();
      } else if (e.key === "Escape") {
        hide();
      }
    },
    [show, disablePrev, disableNext, hide]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyNavigation);
    return () => window.removeEventListener("keydown", handleKeyNavigation);
  }, [handleKeyNavigation]);

  return (
    <>
      <Modal
        show={show}
        onHide={hide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName={`modal-100w position-relative ${showFullScreenQuestionModal && "modal-fullscreen"}`}
      >
        <Modal.Header className="d-flex ">
          <div style={{ width: "25%" }}>
            <Modal.Title id="contained-modal-title-vcenter " className="mb-0 text-nowrap">
              Question View
            </Modal.Title>
            <div className="row">
              <div className="col-12">
                <p className="text-muted mb-0 text-nowrap">{QUESTION_TYPE_MAP[question?.questionId?.type]}</p>
              </div>
            </div>
          </div>
          <div className="mx-auto" style={{ width: "50%" }}>
            <Timer
              isRunning={timerActive}
              onTimerStart={() => setTimerActive(true)}
              questionId={question?.questionId?._id}
            />
          </div>
          <div style={{ width: "25%" }} className="d-flex align-items-center gap-3">
            <SingleBookmark
              question={question}
              questionId={question?.questionId?._id}
              notebookId={notebookId}
              refresh={() => {
                hide();
                refresh();
              }}
            />
            <div style={{ display: "flex", alignItems: "center" }}>
              <X style={{ cursor: "pointer", width: "24px", height: "24px" }} onClick={hide} />
            </div>
          </div>
        </Modal.Header>

        <Modal.Body
          ref={modalBodyRef}
          style={{ maxHeight: "calc(100vh - 150px)", overflowY: "auto", position: "relative" }}
        >
          <Question question={question} onSubmit={() => setTimerActive(false)} />
        </Modal.Body>

        <button
          className="btn d-flex justify-content-center p-0 align-items-center rounded-circle"
          onClick={handlePrevQuestion}
          disabled={disablePrev}
          style={{
            ...buttonStyle,
            left: getButtonPosition(),
          }}
        >
          <ChevronLeft size="24" color="var(--icon)" />
        </button>
        <button
          className="btn d-flex justify-content-center p-0 align-items-center rounded-circle"
          onClick={handleNextQuestion}
          disabled={disableNext}
          style={{
            ...buttonStyle,
            right: getButtonPosition(),
          }}
        >
          <ChevronRight size="24" color="var(--icon)" />
        </button>
      </Modal>
    </>
  );
};

export default QuestionView;
